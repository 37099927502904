import React from 'react';
import { Link } from "gatsby";


const Demo = ({className, ...rest}) => {
	return (
        <div className={className} {...rest}>
			<div className='pt-10 row text-center'>
				<div className='col-xl-6 col-lg-6 col-xs-12'>
					<div className='text-left m-5 p-10 grey rounded-5'>
						<h2 className='h3'>Project Assistance</h2>
						<p>Don't have time to learn our software but would like to still use it? One of our experts will happily do all the work for you no matter how big or how small your project is.</p>
						<Link
                            to="/project-assistance"
                            className="font-size-5 Videos gr-hover-text-dodger-blue-1"
                          >
                            Read more on Project Assistance >
                          </Link>
					</div>
				</div>
				<div className='col-xl-6 col-lg-6 col-xs-12'>
					<div className='text-left m-5 p-10 grey rounded-5'>
						<h2 className='h3'>1:1 Training Sessions</h2>
						<p>One on one training sessions are available for you and your team, so our experts can train you to become Interactive Content gurus. FREE with the Agency plan.</p>
						<p>
						<Link
                            to="/contact"
                            className="font-size-5 Videos gr-hover-text-dodger-blue-1"
                          >
                            Get in touch >
                          </Link>
						</p>

					</div>
				</div>                         
			</div>
		</div>
	);
};

export default Demo;
