import React from "react";
import PageWrapper from "../components/PageWrapper";
import Pricing from "../sections/home4/Pricing";
import Help from "../sections/common/Help";
import Compare from "../sections/common/Compare";
import Commit from "../sections/common/Commit";

import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/plans.jpg'


const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          version: 'pricing-version',
          footerStyle: "digma",
        }}>
        <SEO
          title="Monthly Payment Subscription Pricing &amp; Plans"
          description="We have payment plans to suit campaigns, small to medium business and agencies"
          image={metaImg}
        />                    
        <Pricing className="mt-15 grey pt-15 pb-20" />
        <Help className="container" />
        <Compare className="d-none d-md-block container pb-10" />  
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
        

      </PageWrapper>
    </>
  );
};
export default PricingPage;
