import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';

const Demo = ({className, ...rest}) => {
	return (
        <div className={className} {...rest}>
        <h3 className='text-center p-10'>Plan comparison</h3>
			<div className='content p-5 text-center'>
				<div className='row pb-5'>
					<div className='col h4'></div>
					<div className='col h5 Videos'>Starter</div>
					<div className='col h5 Images'>Standard</div>
					<div className='col h5 Forms'>Professional</div>
					<div className='col h5 Links'>Enterprise</div>
				</div>
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Interactive Videos</div>
					<div className='col'>1</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>Interactive Images</div>
					<div className='col'>1</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Popups</div>
					<div className='col'>1</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>				 
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>QR Generator</div>
					<div className='col'>1</div>
					<div className='col'>10</div>
					<div className='col'>100</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div> 
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Short Links</div>
					<div className='col'>1</div>
					<div className='col'>10</div>
					<div className='col'>100</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>                                  
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>Forms</div>
					<div className='col'>1</div>
					<div className='col'>10</div>
					<div className='col'>100</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>                                                                
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Branding</div>
					<div className='col'>1</div>
					<div className='col'>1</div>
					<div className='col'>5</div>
					<div className='col'><FontAwesomeIcon icon={faInfinity} /></div>
				</div>
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>Analytics</div>
					<div className='col'>Basic</div>
					<div className='col'>Basic</div>
					<div className='col'>Advanaced</div>
					<div className='col'>Advanaced</div>
				</div>
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Training</div>
					<div className='col'>-</div>
					<div className='col'>-</div>
					<div className='col'>Half-day</div>
					<div className='col'>Full-day</div>
				</div>              
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>
						Account manager
					</div>
					<div className='col'>-</div>
					<div className='col'>-</div>
					<div className='col'>-</div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
				</div>                              
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>Whitelabel</div>
					<div className='col'>-</div>
					<div className='col'>-</div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
				</div> 
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>Link-in-bio</div>
					<div className='col'>-</div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
				</div>                     
				<div className='row grey pt-5'>
					<div className='col h4 font-size-6 text-left'>
						Landing Pages
					</div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
				</div>                
				<div className='row pt-5'>
					<div className='col h4 font-size-6 text-left'>
						GTM Intergration
					</div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
					<div className='col'><i className="fa fa-check mr-4 bg-video border-0 text-white" /></div>
				</div>                            
			</div>
		</div>
	);
};

export default Demo;
